<template>
  <div class="fill-height py-3 py-md-10">
    <Loading :loading="isLoading"></Loading>
    <v-container class="flex-center fill-height ">
      <div class=" elevation-17">
       <div class="text-center mt-5 txt-m-large mb-3 align-item-center">
            Reset Password
          </div>
          <div class="pa-5">
            <div class="mb-5">
              <label for="Password">Password</label>
              <TextField
                type="password"
                placeholder="Password"
                :value="password"
                @onChange="password = $event"
              ></TextField>
            </div>
            <div class="mb-5">
              <label for="Retype Password">Retype Password</label>
              <TextField
                type="password"
                placeholder="Retype Password"
                :value="confirmPassword"
                @onChange="confirmPassword = $event"
              ></TextField>
            </div>
            <v-btn @click="onResetPassword" class="cust-btn-color mt-3" block>
              Reset Password
            </v-btn>
          </div>
          </div>
    </v-container>
  </div>
</template>

<script>
import TextField from '../components/input/TextField'
import Service from '../services'
import Loading from '../components/Loading'

export default {
name: "ResetPassword",
props: ['token'],
components: {
  TextField,
  Loading
},
data() {
  return {
    password: '',
    confirmPassword: '',
    isLoading: false
  }
},
methods: {
  async onResetPassword() {
    if (this.password !== this.confirmPassword) {
      this.$swal.fire({
          ...this.$swalError,
          text: "Password is not match."
        })
    return
  }
  this.isLoading = true
  let res 
  try {
    res = await Service.auth.confirmResetPassword({
      token: this.$route.query.token,
      password: this.password
    })
  } catch(err) {
    this.isLoading = false
     this.$swal.fire({
          ...this.$swalError,
          text: err.message
        })
    return
  }
this.isLoading = false
   this.$swal.fire({
           ...this.$swalSuccess,
            text: 'Reset Password Success You can use youe new password.'
      });

      this.$router.push('/')
  }
},
mounted() {
  this.$store.commit('Auth/loginDialogHandle',false)
  if (!this.$route.query.token) {
    return this.$router.push('PageNotFound')
  }
}
}
</script>

<style scoped>
  .notfound-text {
    font-size: 50px;
    color: rgba(0,0,0,.3);
  }
</style>
